// react
import * as React from "react"

// gsap
import gsap from "gsap"

// default template
import Template from "../template"

// hocs
import withDataAsync from "../../core/hocs/data-async"
import withProps from "../../core/hocs/props"

// components
import {
  SEO,
  AnimateIntroBanner,
  AnimateIntroRow,
  AnimateIntroBlock,
  AnimateIntroSelf,
  Row,
  RowLines,
  Block,
  Heading,
  LearnMore,
  Anchor,
  DescriptionList,
  Paragraph,
  Decoration,
  BlockNextProjectMarquee,
  SharePage,
} from "../components"

// fetchers
import fetchDirectusProjects from "../fetchers/directus-projects"

// queries
import useDirectus from "../queries/directus"

// utilities
import by from "../../core/utility/by"
import capitalize from "../../core/utility/capitalize"

// animations
import withScrollMagicAnimation from "../../core/animations/scroll-magic"

/**
 * Manage ScrollMagic animations
 */
const ScrollMagicLogoVisibility = withScrollMagicAnimation(
  null,
  {},
  (scene, getElements) => {
    const offset = 1 // immediately on scroll
    const selector = "#banner .project .details"
    const duration = 0.75
    const ease = "expo.out"

    scene({ offset })
      .on("leave", event => {
        gsap.fromTo(
          getElements(selector),
          {
            x: "32px",
            y: 0,
          },
          {
            duration,
            ease: "expo.out",
            opacity: 1,
            display: "flex",
            x: 0,
            y: 0,
          }
        )
      })
      .on("enter", event => {
        gsap.fromTo(
          getElements(selector),
          {
            x: 0,
            y: 0,
          },
          {
            duration,
            ease: "expo.out",
            opacity: 0,
            x: "32px",
            y: 0,
            display: "none",
          }
        )
      })
  }
)

/**
 * Client-side page matching for /project/*
 */
const Project = ({ "*": slug }) => {
  const client = useDirectus().directus
  const projects = client.getItems("projects")

  const ProjectWithData = withDataAsync(
    ({ data }) => {
      // get result if one exists yet
      const { directus: result } = data
      if (!result) return null

      // select project
      const { projects } = result
      const project = projects.filter(by("slug", slug))[0]
      if (!project) return "error 404" // redirect to 404 page please

      // get next project
      let index = projects.indexOf(projects.find(p => p.slug === project.slug))
      let nextIndex = index + 1
      if (nextIndex >= projects.length) {
        nextIndex = 0
      }
      const nextProject = Object.assign({}, projects[nextIndex])

      // get featured image
      project.featuredImage = project.images[0]

      // fix spaces
      project.content = project.content.replace(/&nbsp;/g, " ")

      return (
        <>
          <SEO title={project.title} description={project.description} />
          <AnimateIntroBanner
            id="banner"
            className="background_image"
            background={
              <>
                <RowLines />
                <Decoration
                  src={project.featuredImage.src}
                  srcSet={project.featuredImage.srcSet}
                />
              </>
            }
          >
            <Block>
              <Heading>{project.title}</Heading>
              <LearnMore elementId="description" color="white" />

              <div className="project">
                <DescriptionList
                  data={{
                    items: [
                      {
                        title: "Category",
                        content: project.category,
                      },
                      {
                        title: "Role",
                        content: project.role,
                      },
                      {
                        title: "Location",
                        content: project.location,
                      },
                    ],
                  }}
                  className="details"
                />
              </div>
            </Block>
            <ScrollMagicLogoVisibility />
          </AnimateIntroBanner>

          {project.content && (
            <AnimateIntroRow id="description" background={<RowLines />}>
              <Block>
                <Heading level={3} className="no_animation">
                  Description
                </Heading>
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: project.content }}
                />
              </Block>
              <Block>
                <DescriptionList
                  data={{
                    items: [
                      {
                        title: "Client",
                        content: project.client,
                      },
                      {
                        title: "Year",
                        content: project.year,
                      },
                      project.keywords.length
                        ? {
                            title: "Keywords",
                            content: project.keywords
                              .map(keyword =>
                                keyword
                                  .split(" ")
                                  .map(capitalize)
                                  .join(" ")
                              )
                              .join(", "),
                          }
                        : false,
                      project.bua !== "XXX"
                        ? {
                            title: "BUA",
                            html: project.bua.replace(/m2$/, "m<sup>2</sup>"),
                          }
                        : false,
                    ].filter(n => n),
                  }}
                  className="details stagger_list"
                />
              </Block>
            </AnimateIntroRow>
          )}

          {project.images && project.images.length > 1 && (
            <Row
              id="gallery"
              className={`images-${project.images.length - 1} ${project.slug}`}
              background={<RowLines />}
            >
              <Block>
                {project.images
                  .filter((v, i) => i)
                  .map(({ src, srcSet }, i) => (
                    <Decoration src={src} srcSet={srcSet} key={i} />
                  ))}
              </Block>
            </Row>
          )}

          <Row id="share" background={<RowLines />}>
            <AnimateIntroBlock>
              <SharePage />
            </AnimateIntroBlock>
          </Row>
        </>
      )
    },
    fetchDirectusProjects,
    null,
    useDirectus
  )

  return (
    <Template
      pageContext={{
        frontmatter: {
          template: `project`,
        },
      }}
    >
      <ProjectWithData />
    </Template>
  )
}
export default Project
